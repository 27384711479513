/*
.custom-table div table tbody tr td {
  padding: 5px 3px;
}
*/

/*
.custom-table div table {
  padding: 10px 10px;
}
*/

/*
.custom-table {
  // margin: 0px 17px; 
   width: 100%;
  // margin-top: 20px; 
}*/

/*
.custom-table tbody tr td div.MuiBox-root {
  width: auto;
  min-width: 160px;
}
*/

/*
.custom-table tbody tr:last-child td div.MuiBox-root {
  width: auto;
  min-width: 90px;
}
*/

/*
.custom-table thead tr th div.MuiBox-root {
  opacity: 1;
  font-size: 0.75rem;
}
*/

/*
.custom-table div.MuiTableContainer-root {
  overflow-x: unset;
}
*/

/*
.custom-table-data {
  margin: 0px 3px;
}
*/

.bill-lad-clear {
  cursor: pointer;
  font-size: 14px !important;
}

.bill-lad-download-preview-save {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.bill-lad-upload-clear {
  margin-bottom: 10px;
}

.bill-lad-custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, min-content);
  gap: 10px 10px;
  width: 97%;
}

.bill-lad-shipper {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 4;
}

.bill-lad-shipper-reference {
  grid-column-start: 3;
  grid-column-end: 4;
}

.bill-lad-shipper-reference-second {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
}

.bill-lad-carrier-reference {
  grid-column-start: 3;
  grid-column-end: 4;
}

.bill-lad-unique-consignment-reference {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-consignee {
  grid-column-start: 1;
  grid-column-end: 3;
}

.bill-lad-carrier-name {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-notify-party {
  grid-column-start: 1;
  grid-column-end: 3;
}

.bill-lad-additional-notify-party {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-pre-carriage-by {
  grid-column-start: 1;
  grid-column-end: 2;
}

.bill-lad-place-of-receipt {
  grid-column-start: 2;
  grid-column-end: 3;
}

.bill-lad-additional-information {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-data-table {
  grid-column-start: 1;
  grid-column-end: 5;
}

.bill-lad-transport-voyageno {
  grid-column-start: 1;
  grid-column-end: 2;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.bill-lad-transport {
  width: 48%;
}

.bill-lad-voyageno {
  width: 48%;
}

.bill-lad-place-of-delivery {
  grid-column-start: 2;
  grid-column-end: 3;
}

.bill-lad-port-of-loading {
  grid-column-start: 3;
  grid-column-end: 4;
}

.bill-lad-port-of-discharge {
  grid-column-start: 4;
  grid-column-end: 5;
}

.bill-lad-netWeight-grossWeight-volume {
  grid-column-start: 1;
  grid-column-end: 5;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
}

.bill-lad-additional-information-second {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 10;
  grid-row-end: 14;
}

.bill-lad-signatureCanvas {
  background-color: #0f1535 !important;
  color: white;
  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}

.bill-lad-delete-icon-style {
  cursor: pointer;
}

.bill-lad-signatory-company {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-authorized-Signatory {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.bill-lad-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.bill-lad-add-shipper label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.bill-lad-add-shipper label svg {
  width: 22px;
  height: 22px;
}
