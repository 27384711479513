@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css);
@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css);


/*.custom-table div table tbody tr td {
  padding: 5px 3px;
}*/

/*.custom-table div table {
  padding: 10px 10px;
}*/

/*.custom-table {
   margin: 0px 17px; 
   width: 100%;
   margin-top: 20px; 
}*/

/*.custom-table tbody tr td div.MuiBox-root {
  width: auto;
  min-width: 160px;
}*/

/*.custom-table tbody tr:last-child td div.MuiBox-root {
  width: auto;
  min-width: 90px;
}*/

/*.custom-table thead tr th div.MuiBox-root {
  opacity: 1;
  font-size: 0.75rem;
}*/

/*.custom-table div.MuiTableContainer-root {
  overflow-x: unset;
}*/

/*.custom-table-data {
  margin: 0px 3px;
}*/

.inv-datatable td.MuiBox-root {
  padding-right: 0;
  padding-left: 0;
}

.inv-datatable td div.MuiBox-root {
  width: 10rem;
}

.inv-datatable td:last-child div.MuiBox-root {
  width: 2rem;
}

.inv-clear {
  cursor: pointer;
  font-size: 14px !important;
}

.inv-download-preview-save {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.inv-upload-clear {
  margin-bottom: 10px;
}

.inv-custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, -webkit-min-content);
  grid-template-rows: repeat(14, min-content);
  grid-gap: 10px 10px;
  gap: 10px 10px;
  width: 97%;
}

.inv-from {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.inv-page {
  text-align: end;
  margin-top: 1rem;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
}

.inv-number {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
}

.inv-date1 {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
}

.inv-buyer {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 3;
}

.inv-to {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
}

.inv-method-of-dispatch {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
}

.inv-type-of-shipment {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
}

.inv-port-loading {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
}

.inv-port-discharge {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 4;
}

.inv-data-table {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
}

.inv-unitType-price-amount {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 6;
}

.inv-additional-inform {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 7;
  grid-row-end: 11;
}

.inv-place-of-issue {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 7;
}

.inv-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 7;
}

.inv-signatory-company {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 8;
  grid-row-end: 8;
}

.inv-authorized-Signatory {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 9;
  grid-row-end: 9;
}

.inv-signature {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 11;
}

.inv-signatureCanvas {
  background-color: #0f1535 !important;
  color: #607d8b;
  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}

.inv-bank-details {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 10;
  grid-row-end: 12;
}

.inv-add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.inv-add-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.inv-add-from label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.inv-add-from label svg {
  width: 22px;
  height: 22px;
}

.inv-delete-icon-style {
  cursor: pointer;
}

.com-datatable td.MuiBox-root {
  padding-right: 0;
  padding-left: 0;
}

.com-datatable td div.MuiBox-root {
  width: 10rem;
}

.com-datatable td:last-child div.MuiBox-root {
  width: 2rem;
}

.com-invo-clear {
  cursor: pointer;
  font-size: 14px !important;
}

.com-invo-download-preview-save {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.com-invo-upload-clear {
  margin-bottom: 10px;
}

.com-invo-custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, -webkit-min-content);
  grid-template-rows: repeat(14, min-content);
  grid-gap: 10px 10px;
  gap: 10px 10px;
  width: 97%;
}

.com-invo-exporter {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.com-invo-page {
  text-align: end;
  margin-top: 1rem;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
}

.com-invo-invoice-number {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
}

.com-invo-invoice-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
}

.com-invo-bill-of-lading-number {
  grid-column-start: 3;
  grid-row-start: 3;
}

.com-invo-reference {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
}

.com-invo-buyer-reference {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 4;
}

.com-invo-consignee {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
}

.com-invo-buyer-if-not-consignee {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 5;
}

.com-invo-method-of-dispatch {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 6;
}

.com-invo-type {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 6;
}

.com-invo-country-of-goods {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 6;
}

.com-invo-transport {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 7;
}

.com-invo-voyage-no {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 7;
}

.com-invo-country-of-final-destination {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 7;
}

.com-invo-port-of-loading {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 8;
}

.com-invo-port-of-discharge {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 8;
}

.com-invo-term-of-payment {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 8;
}

.com-invo-place-of-origin {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 8;
}

.com-invo-date-of-departure {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 9;
}

.com-invo-final-destination {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 9;
}

.com-invo-marine-cover-policy-no {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 9;
}

.com-invo-letter-of-credit-no {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 9;
}

.com-invo-data-table {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 10;
}

.com-invo-unitType-price-amount {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 11;
}

.com-invo-additional-information {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 12;
  grid-row-end: 16;
}

.com-invo-place-of-issue {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 12;
}

.com-invo-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 12;
}

.com-invo-signatory-company {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 13;
  grid-row-end: 13;
}

.com-invo-authorized-Signatory {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 14;
  grid-row-end: 14;
}

.com-invo-signature {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 16;
}

.com-invo-signatureCanvas {
  background-color: #0f1535 !important;
  color: #607d8b;
  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}

.com-invo-bank-details {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 15;
  grid-row-end: 17;
}

.com-invo-add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.com-invo-add-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.com-invo-add-exporter label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.com-invo-add-exporter label svg {
  width: 22px;
  height: 22px;
}

.com-delete-icon-style {
  cursor: pointer;
}

.quo-datatable td.MuiBox-root {
  padding-right: 0;
  padding-left: 0;
}

.quo-datatable td div.MuiBox-root {
  width: 10rem;
}

.quo-datatable td:last-child div.MuiBox-root {
  width: 2rem;
}

.quo-clear {
  cursor: pointer;
  font-size: 14px !important;
}

.quo-download-preview-save {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.quo-upload-clear {
  margin-bottom: 10px;
}

.quo-custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, -webkit-min-content);
  grid-template-rows: repeat(14, min-content);
  grid-gap: 10px 10px;
  gap: 10px 10px;
  width: 97%;
}

.quo-from {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.quo-page {
  text-align: end;
  margin-top: 1rem;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
}

.quo-number {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
}

.quo-date1 {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
}

.quo-to {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
}

.quo-port-of-loading {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
}

.quo-port-of-discharge {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 3;
}

.quo-method-of-dispatch {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
}

.quo-type-of-shipment {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 4;
}

.quo-data-table {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
}

.quo-unitType-price-amount {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 6;
}

.quo-additional-inform {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 7;
  grid-row-end: 11;
}

.quo-place-of-issue {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 7;
}

.quo-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 7;
}

.quo-signatory-company {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 8;
  grid-row-end: 8;
}

.quo-authorized-Signatory {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 9;
  grid-row-end: 9;
}

.quo-signature {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 11;
}

.quo-signatureCanvas {
  background-color: #0f1535 !important;
  color: #607d8b;
  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}

.quo-bank-details {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 10;
  grid-row-end: 12;
}

.quo-add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.quo-add-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.quo-add-from label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.quo-add-from label svg {
  width: 22px;
  height: 22px;
}

.quo-delete-icon-style {
  cursor: pointer;
}

.purchase-datatable td.MuiBox-root {
  padding-right: 0;
  padding-left: 0;
}

.purchase-datatable td div.MuiBox-root {
  width: 10rem;
}

.purchase-datatable td:last-child div.MuiBox-root {
  width: 2rem;
}

.purchase-clear {
  cursor: pointer;
  font-size: 14px !important;
}

.purchase-download-preview-save {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.purchase-upload-clear {
  margin-bottom: 10px;
}

.purchase-custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, -webkit-min-content);
  grid-template-rows: repeat(14, min-content);
  grid-gap: 10px 10px;
  gap: 10px 10px;
  width: 97%;
}

.purchase-from {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.purchase-page {
  text-align: end;
  margin-top: 1rem;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
}

.purchase-invoice-number {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
}

.purchase-invoice-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
}

.purchase-buyer {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 3;
}

.purchase-to {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
}

.purchase-method-of-dispatch {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 4;
}

.purchase-type-of-shipment {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 4;
}

.purchase-port-loading {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 4;
}

.purchase-port-discharge {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 4;
}

.purchase-data-table {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
}

.purchase-unitType-price-amount {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 6;
}

.purchase-additional-inform {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 7;
  grid-row-end: 11;
}

.purchase-place-of-issue {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 7;
}

.purchase-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 7;
}

.purchase-signatory-company {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 8;
  grid-row-end: 8;
}

.purchase-authorized-Signatory {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 9;
  grid-row-end: 9;
}

.purchase-signature {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 11;
}

.purchase-bank-details {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 10;
  grid-row-end: 12;
}

.purchase-signatureCanvas {
  background-color: #0f1535 !important;
  color: #607d8b;
  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}

.purchase-add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.purchase-add-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.purchase-add-from label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.purchase-add-from label svg {
  width: 22px;
  height: 22px;
}

.purchase-delete-icon-style {
  cursor: pointer;
}

.Tab.css-hvp8yb-MuiButtonBase-root-MuiTab-root {
  color: black !important;
}

/* .Tab.css-hvp8yb-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: white !important;
} */

/*
.custom-table div table tbody tr td {
  padding: 5px 3px;
}
*/

/*
.custom-table div table {
  padding: 10px 10px;
}
*/

/*
.custom-table {
  // margin: 0px 17px; 
   width: 100%;
  // margin-top: 20px; 
}*/

/*
.custom-table tbody tr td div.MuiBox-root {
  width: auto;
  min-width: 160px;
}
*/

/*
.custom-table tbody tr:last-child td div.MuiBox-root {
  width: auto;
  min-width: 90px;
}
*/

/*
.custom-table thead tr th div.MuiBox-root {
  opacity: 1;
  font-size: 0.75rem;
}
*/

/*
.custom-table div.MuiTableContainer-root {
  overflow-x: unset;
}
*/

/*
.custom-table-data {
  margin: 0px 3px;
}
*/

.bill-lad-clear {
  cursor: pointer;
  font-size: 14px !important;
}

.bill-lad-download-preview-save {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.bill-lad-upload-clear {
  margin-bottom: 10px;
}

.bill-lad-custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, -webkit-min-content);
  grid-template-rows: repeat(14, min-content);
  grid-gap: 10px 10px;
  gap: 10px 10px;
  width: 97%;
}

.bill-lad-shipper {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 4;
}

.bill-lad-shipper-reference {
  grid-column-start: 3;
  grid-column-end: 4;
}

.bill-lad-shipper-reference-second {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
}

.bill-lad-carrier-reference {
  grid-column-start: 3;
  grid-column-end: 4;
}

.bill-lad-unique-consignment-reference {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-consignee {
  grid-column-start: 1;
  grid-column-end: 3;
}

.bill-lad-carrier-name {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-notify-party {
  grid-column-start: 1;
  grid-column-end: 3;
}

.bill-lad-additional-notify-party {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-pre-carriage-by {
  grid-column-start: 1;
  grid-column-end: 2;
}

.bill-lad-place-of-receipt {
  grid-column-start: 2;
  grid-column-end: 3;
}

.bill-lad-additional-information {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-data-table {
  grid-column-start: 1;
  grid-column-end: 5;
}

.bill-lad-transport-voyageno {
  grid-column-start: 1;
  grid-column-end: 2;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.bill-lad-transport {
  width: 48%;
}

.bill-lad-voyageno {
  width: 48%;
}

.bill-lad-place-of-delivery {
  grid-column-start: 2;
  grid-column-end: 3;
}

.bill-lad-port-of-loading {
  grid-column-start: 3;
  grid-column-end: 4;
}

.bill-lad-port-of-discharge {
  grid-column-start: 4;
  grid-column-end: 5;
}

.bill-lad-netWeight-grossWeight-volume {
  grid-column-start: 1;
  grid-column-end: 5;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.bill-lad-additional-information-second {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 10;
  grid-row-end: 14;
}

.bill-lad-signatureCanvas {
  background-color: #0f1535 !important;
  color: white;
  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}

.bill-lad-delete-icon-style {
  cursor: pointer;
}

.bill-lad-signatory-company {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-authorized-Signatory {
  grid-column-start: 3;
  grid-column-end: 5;
}

.bill-lad-add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.bill-lad-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.bill-lad-add-shipper label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.bill-lad-add-shipper label svg {
  width: 22px;
  height: 22px;
}

/* Full Calendar */

.fc-toolbar-title {
  color: white !important;
}
a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}
a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}
a.fc-daygrid-day-number {
  width: 100%;
  text-align: center;
}
div.fc-daygrid-event-harness {
  top: unset;
}
.fc-daygrid-event-harness .fc-daygrid-event-harness-abs {
  top: 0px;
  bottom: unset;
}
.fc .fc-scrollgrid-section-header > *,
.fc .fc-scrollgrid-section-footer > * {
  border-right: 0px !important;
}
.fc-theme-standard .fc-scrollgrid {
  border: 0px solid transparent !important;
}

.fc-col-header-cell.fc-day.fc-day-mon {
  color: white;
  border-right: 0px;
  border-left: 0px;
}
.fc-col-header-cell.fc-day.fc-day-tue {
  color: white;
  border-left: 0px;
  border-right: 0px;
}
.fc-col-header-cell.fc-day.fc-day-wed {
  color: white;
  border-right: 0px;
  border-left: 0px;
}
.fc-col-header-cell.fc-day.fc-day-thu {
  color: white;
  border-left: 0px;
  border-right: 0px;
}
.fc-col-header-cell.fc-day.fc-day-fri {
  color: white;
  border-right: 0px;
  border-left: 0px;
}
.fc-col-header-cell.fc-day.fc-day-sat {
  color: white;
  border-left: 0px;
  border-right: 0px;
}
.fc-col-header-cell.fc-day.fc-day-sun {
  color: white;
  border-left: 0px;
  border-right: 0px;
}

.fc-daygrid-event {
  border-radius: 10px !important;
  text-align: center !important;
  font-family: "Plus Jakarta Display" !important;
}
.fc-day {
  color: white;
}
.fc-day.fc-day-mon {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-tue {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-wed {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-thu {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-fri {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-sat {
  border: 1px solid !important;
  border-color: #292e5d !important;
}
.fc-day.fc-day-sun {
  border: 1px solid !important;
  border-color: #292e5d !important;
}

.fc-scrollgrid.fc-scrollgrid-liquid > td {
  border: 0px;
}

.fc .fc-scrollgrid-section > td {
  border: 0;
}

/* React Kanban */
body .react-kanban-column {
  background: linear-gradient(
    127.09deg,
    rgba(6, 11, 40, 0.94) 19.41%,
    rgba(10, 14, 35, 0.49) 76.65%
  );
  background-color: unset !important;
  border-radius: 15px;
}
.react-kanban-column {
  width: 500px;
}
.react-kanban-card-adder-form {
  width: 100%;
  background-color: red;
  border-radius: 15px;
}
.react-kanban-card-adder-button {
  display: none;
  background-color: red !important;
  border-radius: 15px;
}

/* Text Editor */

body .ql-toolbar.ql-snow {
  background-color: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

body .ql-container.ql-snow {
  background-color: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

body .ql-snow .ql-picker-label {
  color: rgba(255, 255, 255, 0.92) !important;
}

body .ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.92) !important;
}

body .ql-snow .ql-fill {
  fill: rgba(255, 255, 255, 0.92) !important;
}

/* .ql-snow .ql-picker-label:hover {
    color: rgba(255, 255, 255, 0.92) !important;
  }
  
  .ql-snow .ql-stroke:hover {
    stroke: rgba(255, 255, 255, 0.92) !important;
  }
  
  .ql-snow .ql-fill:hover {
    fill: rgba(255, 255, 255, 0.92) !important;
  } */

.ql-picker-options {
  background: #0f1535 !important;
  border: 0.5px solid #e2e8f04d;
}

.ql-container.ql-snow {
  color: rgb(160, 174, 192);
  min-height: 88px !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #0075ff;
  font-size: 12px;
  border: 1px solid #3e549a;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.swal-text {
  background-color: #ffffff;
  padding: 17px;
  display: block;
  margin: 22px;
  text-align: center;
  color: black;
  box-shadow: 0px 0px 0px white;
}

.swal-modal {
  background-color: #ffffff;
  box-shadow: 0px 0px 0px white;
  border-radius: 5px;
}

/* .profile-main-div {
  color: white;
}

.user-profile-card {
  margin: 5px 0px;
}

.user-profile {
  display: flex;
}

.user-logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.user-name {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.user-name p {
  color: white;
} */

/* .about-card {
  margin: 5px 0px;
} */

/* .about .heading {
  color: white;
} */

.about .profile-info {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 60%;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.about .profile-info .label-name {
  color: gray;
  font-size: 14px;
  font-weight: bold;
}

.profile-information-card {
  margin: 5px 0px;
}

.profile-information .heading {
  color: white;
}

.profile-information .profile-info {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 60%;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.profile-information .profile-info .label-name {
  color: gray;
  font-size: 14px;
  font-weight: bold;
}

.email-notification {
  color: white;
}

.email-checkbox .MuiFormControlLabel-label {
  color: white !important;
}

.change-password-card {
  margin: 5px 0px;
}

.change-password .heading {
  color: white;
}

.change-password-button {
  margin-top: 10px;
}

.data-name {
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.edit-div {
  display: flex;
}

.edit-div button {
  font-size: 12px;
  color: #4318ff;
  padding-top: 3px;
}

.edit-div button:hover {
  color: #4318ff !important;
  background-color: transparent;
  -webkit-transform: none !important;
          transform: none !important;
}

.save-div {
  display: flex;
  align-items: center;
}

.save-div button {
  font-size: 12px;
  color: #4318ff !important;
  padding-top: 3px;
}

.save-div button:hover {
  color: #4318ff !important;
  background-color: transparent;
  -webkit-transform: none !important;
          transform: none !important;
}

