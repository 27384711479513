.com-datatable td.MuiBox-root {
  padding-right: 0;
  padding-left: 0;
}

.com-datatable td div.MuiBox-root {
  width: 10rem;
}

.com-datatable td:last-child div.MuiBox-root {
  width: 2rem;
}

.com-invo-clear {
  cursor: pointer;
  font-size: 14px !important;
}

.com-invo-download-preview-save {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.com-invo-upload-clear {
  margin-bottom: 10px;
}

.com-invo-custom-grid-form {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: repeat(14, min-content);
  gap: 10px 10px;
  width: 97%;
}

.com-invo-exporter {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.com-invo-page {
  text-align: end;
  margin-top: 1rem;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
}

.com-invo-invoice-number {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
}

.com-invo-invoice-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
}

.com-invo-bill-of-lading-number {
  grid-column-start: 3;
  grid-row-start: 3;
}

.com-invo-reference {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
}

.com-invo-buyer-reference {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 4;
}

.com-invo-consignee {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
}

.com-invo-buyer-if-not-consignee {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 5;
}

.com-invo-method-of-dispatch {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 6;
}

.com-invo-type {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 6;
}

.com-invo-country-of-goods {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 6;
}

.com-invo-transport {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 7;
}

.com-invo-voyage-no {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 7;
}

.com-invo-country-of-final-destination {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 7;
}

.com-invo-port-of-loading {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 8;
}

.com-invo-port-of-discharge {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 8;
}

.com-invo-term-of-payment {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 8;
}

.com-invo-place-of-origin {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 8;
}

.com-invo-date-of-departure {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 9;
}

.com-invo-final-destination {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 9;
}

.com-invo-marine-cover-policy-no {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 9;
}

.com-invo-letter-of-credit-no {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 9;
}

.com-invo-data-table {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 10;
}

.com-invo-unitType-price-amount {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 11;
}

.com-invo-additional-information {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 12;
  grid-row-end: 16;
}

.com-invo-place-of-issue {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 12;
}

.com-invo-date {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 12;
}

.com-invo-signatory-company {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 13;
  grid-row-end: 13;
}

.com-invo-authorized-Signatory {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 14;
  grid-row-end: 14;
}

.com-invo-signature {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 16;
}

.com-invo-signatureCanvas {
  background-color: #0f1535 !important;
  color: #607d8b;
  width: 70%;
  height: 125px;
  border: 0.0625rem solid rgba(226, 232, 240, 0.3);
  border-radius: 0.9375rem;
}

.com-invo-bank-details {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 15;
  grid-row-end: 17;
}

.com-invo-add-data-table-row label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.com-invo-add-data-table-row label svg {
  width: 22px;
  height: 22px;
}

.com-invo-add-exporter label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  cursor: pointer;
  font-size: 14px;
}

.com-invo-add-exporter label svg {
  width: 22px;
  height: 22px;
}

.com-delete-icon-style {
  cursor: pointer;
}
